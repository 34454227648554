import React, { useState } from "react";
import "../stylesheet/Searchbar.css";
import IndividualEvent from "./IndividualEvent";
import NoEvents from "./NoEvents";

const getStatus = (startDate, endDate) => {
   let status = "";
   const curDate = new Date();
   const sDate = new Date(startDate.split("/")[2], startDate.split("/")[1] - 1, startDate.split("/")[0]);
   const eDate = new Date(endDate.split("/")[2], endDate.split("/")[1] - 1, endDate.split("/")[0]);
   if (curDate - sDate >= 0 && curDate - eDate <= 0) {
      status = "open";
   } else if (sDate - curDate > 0) {
      status = "upcoming";
   } else if (curDate - eDate > 0) {
      status = "ended";
   }
   return status;
};

function SearchEvents({ Events, Filtered }) {
   const [searchbox_event_name, setsearchbox_event_name] = useState("");

   const searchText = (event) => {
      setsearchbox_event_name(event.target.value);
   };

   let dataSearch = Events.filter((item) => {
      return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(searchbox_event_name.toString().toLowerCase()));
   });

   const handleClear = () => {
      setsearchbox_event_name("");
   };
   const events_to_display = searchbox_event_name ? dataSearch : Events;
   //Events to be finally displayed
   let events = events_to_display.filter((e) => (e.location === Filtered[0] || Filtered[0] === "") && (getStatus(e.startDate, e.endDate) === Filtered[1] || Filtered[1] === "") && (e.length === Filtered[2] || Filtered[2] === ""));

   return (
      <div>
         <div className='col-12 text-center search-bar'>
            <div className='search-icon'>
               <i className='fa fa-search'></i>
            </div>
            <input type='text' placeholder='Search Events' value={searchbox_event_name} onChange={searchText.bind(this)} />
            <div onClick={handleClear} className='search-clear'>
               <i className='fa fa-times'></i>
            </div>
         </div>
         {events.length > 0 ? <IndividualEvent Events={events} /> : <NoEvents filter={Filtered} />}
      </div>
   );
}

export default SearchEvents;
