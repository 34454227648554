import React from "react";
import "../stylesheet/IndividualEvent.css";
import "../stylesheet/Responsiveness.css";
export default function IndividualEvent({ Events }) {
   return (
      <div>
         {Events.map((e) => {
            const curDate = new Date();
            const startDate = new Date(e.startDate.split("/")[2], e.startDate.split("/")[1] - 1, e.startDate.split("/")[0]);
            const endDate = new Date(e.endDate.split("/")[2], e.endDate.split("/")[1] - 1, e.endDate.split("/")[0]);
            const registerationDeadline = e.deadline.split("/").reverse().join("/");

            const registerationDate = new Date(registerationDeadline.split("/")[2], registerationDeadline.split("/")[1] - 1, registerationDeadline.split("/")[0]);

            let remainingDays = "";
            let status = "Upcoming";
            if (curDate - startDate >= 0 && curDate - endDate <= 0) {
               status = "On-going";
            } else if (startDate - curDate > 0) {
               status = "Upcoming";

               remainingDays = parseInt((registerationDate - curDate) / (1000 * 60 * 60 * 24), 10);
            } else if (curDate - endDate > 0) {
               status = "Ended";
            }
            const newArray = [e.category].concat(e.tags);
            const listTags = newArray.map((tag) => <span>{tag}</span>);
            return (
               <div className='container individual-event-card' key={e.name}>
                  <div className={` card ${status} row`}>
                     <div className='image col-md-2 col-sm-10'>
                        <img src={e.img} alt=''></img>
                     </div>
                     <div className='left-container col-md-6 col-sm-10'>
                        <div className='event-main-content'>
                           <div className='event_title'>{e.name}</div>

                           <div className='status-btn'>
                              <span>•</span> {status}
                           </div>
                           {remainingDays && <div className='time-rem-btn'>Registration closes in {remainingDays} days</div>}
                        </div>

                        <div className='row event-description'> {e.description}</div>

                        <div className='tag'>
                           <div className='content'>{listTags}</div>
                        </div>
                     </div>
                     <div className='right-container col-md-3 col-sm-10'>
                        <div className='date'>
                           <i className='fa fa-calendar'></i>
                           <div className='content'>
                              {e.startDate} - {e.endDate}
                           </div>
                        </div>
                        <div className='duration'>
                           <i class='fa fa-history'></i>
                           <div className='content'>{e.duration} long</div>
                        </div>
                        <div className='team-size'>
                           <i className='fa fa-users'></i>
                           <div className='content'>{e.teamSize}</div>
                        </div>
                        <div className='prize'>
                           <i className='fa fa-database'></i>
                           <div className='content'>
                              {" "}
                              <span> ₹ {e.prize}</span> in prizes
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            );
         })}
      </div>
   );
}
