import React from "react";

function NoEvents({ filter }) {
   return (
      <div>
         <div className='container individual-event-card'>
            <div className='card row'>
               <div className='text-center col-12'>
                  {" "}
                  <p className='event_title'>Sorry.</p>
                  There is no {filter[1]} {filter[0]} event
               </div>
            </div>
         </div>
      </div>
   );
}

export default NoEvents;
