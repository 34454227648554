import React, { useState, useEffect } from "react";
import "../stylesheet/IndividualEvent.css";
import "../stylesheet/Sidebar.css";
import "../stylesheet/Responsiveness.css";
import "../stylesheet/Mobile-nav.css";
import Events from "../events.json";
import SearchEvents from "./SearchEvents";

export default function SortEvents() {
   const [data, setData] = useState([]);
   const [sortType, setSortType] = useState("prize");

   const [location, setLocation] = useState("online");
   const [status, setStatus] = useState("upcoming");
   const [length, setLength] = useState("");
   const [filterData, setFilterData] = useState([location, status, length]);

   const handleChangeLocation = (e) => {
      setLocation(e.target.value);
      const filtered = filterData;
      filtered[0] = e.target.value;
      setFilterData(filtered);
   };

   const handleChangeStatus = (e) => {
      setStatus(e.target.value);
      const filtered = filterData;
      filtered[1] = e.target.value;
      setFilterData(filtered);
   };

   const handleChangeLength = (e) => {
      setLength(e.target.value);
      const filtered = filterData;
      filtered[2] = e.target.value;
      setFilterData(filtered);
   };

   // Mobile sidebar
   const handleClick = (event) => {
      console.log("clicked");
      const hamburger = document.querySelector(".hamburger");
      const nav = document.querySelector(".navigation");
      hamburger.classList.toggle("hamburger--active");
      nav.classList.toggle("navigation--active");
   };
   const handleClick2 = (event) => {
      console.log("clicked2");
      const hamburger = document.querySelector(".hamburger");
      const nav = document.querySelector(".navigation");
      if (hamburger.classList.contains("hamburger--active")) {
         hamburger.classList.toggle("hamburger--active");
         nav.classList.toggle("navigation--active");
      }
   };

   useEffect(() => {
      const sortArray = (type) => {
         const types = {
            prize: "prize",
            name: "name",
            deadline: "deadline",
            startDate: "startDate",
            endDate: "endDate",
         };
         const sortProperty = types[type];
         if (sortProperty === "prize") {
            const sorted = [...Events].sort((a, b) => (parseInt(b[sortType]) > parseInt(a[sortType]) ? 1 : -1));
            setData(sorted);
         } else if (sortProperty === "name") {
            const sorted = [...Events].sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]));
            setData(sorted);
         } else {
            const sorted = [...Events].sort((a, b) => (b[sortProperty] < a[sortProperty] ? 1 : -1));
            setData(sorted);
         }
      };

      sortArray(sortType);
   }, [sortType]);
   let fData = filterData;

   return (
      <div className='row '>
         <div className='Sidebar'>
            <h5>Sort By:</h5>
            <select onChange={(e) => setSortType(e.target.value)}>
               <option value='prize'>Prize</option>
               <option value='name'>Name</option>
               <option value='deadline'>Registration Deadline</option>
               <option value='startDate'>Start Date</option>
               <option value='endDate'>End Date</option>
            </select>

            <div className='filter'>
               <h5>Status</h5>
               <form>
                  <input type='radio' value='upcoming' id='upcoming' onChange={handleChangeStatus} name='status' />
                  <label htmlFor='upcoming'>Upcoming</label>
                  <br />
                  <input type='radio' value='open' id='open' onChange={handleChangeStatus} name='status' />
                  <label htmlFor='open'>Ongoing</label>
                  <br />
                  <input type='radio' value='ended' id='ended' onChange={handleChangeStatus} name='status' />
                  <label htmlFor='ended'>Ended</label>
               </form>
            </div>

            <div className='filter'>
               <h5>Length</h5>
               <form>
                  <input type='radio' value='1-6 days' id='1-6 days' onChange={handleChangeLength} name='length' />
                  <label htmlFor='1-6 days'>1 - 6 days</label>
                  <br />
                  <input type='radio' value='1-4 weeks' id='1-4 weeks' onChange={handleChangeLength} name='length' />
                  <label htmlFor='1-4 weeks'>1 - 4 weeks</label>
                  <br />
                  <input type='radio' value='1+ month' id='1+ month' onChange={handleChangeLength} name='length' />
                  <label htmlFor='1+ month'>1+ month</label>
               </form>
            </div>
            <div className='filter'>
               <h5>Location</h5>
               <form>
                  <input type='radio' value='online' id='online' onChange={handleChangeLocation} name='location' />
                  <label htmlFor='male'>Online</label>
                  <br />
                  <input type='radio' value='in-person' id='in-person' onChange={handleChangeLocation} name='location' />
                  <label htmlFor='female'>In-person</label>
               </form>
            </div>
         </div>

         {/* --------------MOBILE-------------------------- */}
         <div className='mobile-sidebar'>
            <button className='hamburger pr-10' onClick={handleClick}>
               <span className='hamburger__box'>
                  <span className='hamburger__inner'></span>
               </span>
            </button>
            <div className='navigation'>
               <div className='smaller-screen-sidebar'>
                  <br />
                  <h5 className='sort-by-title'>Sort By:</h5>
                  <select onChange={(e) => setSortType(e.target.value)}>
                     <option value='prize'>Prize</option>
                     <option value='name'>Name</option>
                     <option value='deadline'>Registration Deadline</option>
                     <option value='startDate'>Start Date</option>
                     <option value='endDate'>End Date</option>
                  </select>

                  <div className='filter'>
                     <h5>Location</h5>
                     <form>
                        <input type='radio' value='online' id='online' onChange={handleChangeLocation} name='location' />
                        <label htmlFor='male'>Online</label>
                        <br />
                        <input type='radio' value='in-person' id='in-person' onChange={handleChangeLocation} name='location' />
                        <label htmlFor='female'>In-person</label>
                     </form>
                  </div>

                  <div className='filter'>
                     <h5>Status</h5>
                     <form>
                        <input type='radio' value='upcoming' id='upcoming' onChange={handleChangeStatus} name='status' />
                        <label htmlFor='upcoming'>Upcoming</label>
                        <br />
                        <input type='radio' value='open' id='open' onChange={handleChangeStatus} name='status' />
                        <label htmlFor='open'>Open</label>
                        <br />
                        <input type='radio' value='ended' id='ended' onChange={handleChangeStatus} name='status' />
                        <label htmlFor='ended'>Ended</label>
                     </form>
                  </div>

                  <div className='filter'>
                     <h5>Length</h5>
                     <form>
                        <input type='radio' value='1-6 days' id='1-6 days' onChange={handleChangeLength} name='length' />
                        <label htmlFor='1-6 days'>1 - 6 days</label>
                        <br />
                        <input type='radio' value='1-4 weeks' id='1-4 weeks' onChange={handleChangeLength} name='length' />
                        <label htmlFor='1-4 weeks'>1 - 4 weeks</label>
                        <br />
                        <input type='radio' value='1+ month' id='1+ month' onChange={handleChangeLength} name='length' />
                        <label htmlFor='1+ month'>1+ month</label>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         <div className='' onClick={handleClick2}>
            <br />
            <SearchEvents Events={data} Filtered={fData} />
         </div>
      </div>
   );
}
