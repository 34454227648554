import React from "react";
import "./Header.css";
import mascot from "../../assets/mascot.svg";
import Typewriter from "typewriter-effect";

const Header = () => {
   return (
      <div className='container'>
         <section className='header row'>
            <div className='header-text col-md-8 col-sm-7 '>
               <h1 className='header-excel'>Excel 2021</h1>
               <p>A avenue to Inspire | Innovate | Engineer (typing animation)</p>

               <Typewriter
                  onInit={(typewriter) => {
                     typewriter.typeString("Inspire").pauseFor(1000).deleteAll().typeString("Innovate").pauseFor(1000).deleteAll().typeString("Engineer").pauseFor(1000).deleteAll().start();
                  }}
               />
               <p className='header-desc'>Excel is the techno-managerial symposium of Govt. Model Engineering College, Kochi. With several competitions, workshops and talks, it is the rendezvous of technology and fun!</p>
            </div>
            <img className='excel-logo col-md-3 col-sm-4' src={mascot} />
         </section>
      </div>
   );
};

export default Header;
