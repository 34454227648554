import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/header/Header.jsx";
import SortEvents from "./components/body/SortEvents";
import Footer from "./components/footer/Footer";

function App({ hideLoader }) {
  useEffect(() => {
    hideLoader();
  });
  return (
    <div className="main-div">
      <Header />
      <div className="col-12 preevents-maindiv">
        <div style={{ display: `flex` }}>
          <SortEvents />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
